<template>
  <div>
    <page-header :title="title"></page-header>
    <form @submit.prevent="gravar">
      <div class="modal-body">
        <div class="form-horizontal">
          <vue-element-loading :active="isActive" spinner="bar-fade-scale" color="#428BCA"></vue-element-loading>
          <input-form-text textInput="Nome" :required="true" v-model="form.title" :value="form.title"></input-form-text>
          <input-form-text textInput="Link" v-model="form.url" :value="form.url"></input-form-text>
          <div class="form-group">
            <label class="col-sm-3 control-label no-padding-right">
              Imagem
            </label>
            <div class="col-sm-5">
              <file-upload v-if="!allImages.length" ref="fupload" :url="'/destaque/upload'" :id="null"
                :label="'PNG, JPG, GIF até 10 MB'" :multiple="false"></file-upload>
              <items-edit v-else :files="allImages" url="/destaque/upload" id="id"></items-edit>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label no-padding-right">
              Imagem Mobile
            </label>
            <div class="col-sm-5">
              <file-upload v-if="!allImagesMobile.length" ref="fuploadMobile" :url="'/destaque/upload/mobile'" :id="null"
                :label="'PNG, JPG, GIF até 10 MB'" :multiple="false"></file-upload>
              <items-edit v-else :files="allImagesMobile" url="/destaque/upload/mobile" id="id"></items-edit>
            </div>
          </div>
          <div class="form-group">
            <select-form v-model="form.ativo" :value="form.ativo" name="Ativo" url="/arquivos?cat=1" id="id" titulo="nome"
              layout="true" optionText="Selecione" required="true"></select-form>
          </div>
        </div>
      </div>
      <footer-button :disabled="isActive"></footer-button>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueElementLoading from 'vue-element-loading'
import PageHeader from '@/components/PageHeader'
import InputFormText from '@/components/form/InputForm'
import FooterButton from '@/components/form/FooterButton'
import Permission from '@/mixins/Permission'
import Services from '@/utils/services'
import { sucesso, erro } from '@/components/Message'
import SelectForm from '@/components/form/SelectForm'
import fileUpload from '@/components/form/file-upload/Index'
import ItemsEdit from '@/components/form/file-upload/ItemsEdit'

export default {
  components: {
    PageHeader,
    InputFormText,
    FooterButton,
    VueElementLoading,
    SelectForm,
    fileUpload,
    ItemsEdit,
  },
  mixins: [Permission],
  computed: {
    ...mapGetters(['user'])
  },
  data() {
    return {
      title: 'Destaque',
      form: {
        title: '',
        ativo: null,
        url: ''
      },
      allImages: [],
      allImagesMobile: [],
      isActiveBtn: false,
      isActive: false
    }
  },
  methods: {
    ...mapActions(['getLogsTotal']),
    async gravar() {
      this.isActive = true
      this.isActiveBtn = true
      const body = this.form
      body.title = body.title.trim()
      if (this.$route.params.id) {
        let resp = await Services.put(
          `/destaque/${this.$route.params.id}`,
          body
        ).catch(response => {
          console.log('error: ' + response)
          erro()
        })
        if (!this.allImages.length) {
          this.isActive = false
          const tb = this.$refs.fupload.$children.filter(i => i.$refs.tb)
          const m = tb[0]
          await m.uploadAll(resp)
        }
        if (!this.allImagesMobile.length) {
          this.isActive = false
          const tbm = this.$refs.fuploadMobile.$children.filter(i => i.$refs.tb)
          const mm = tbm[0]
          await mm.uploadAll(resp)
        }
        sucesso('Atualizado...')
        this.$router.push('/sistema/destaque')
      } else {
        let resp = await Services.post('/destaque', body).catch(response => {
          console.log('error: ' + response)
          erro()
        })
        this.isActive = false
        const tb = this.$refs.fupload.$children.filter(i => i.$refs.tb)
        const m = tb[0]
        await m.uploadAll(resp)
        const tbm = this.$refs.fuploadMobile.$children.filter(i => i.$refs.tb)
        const mm = tbm[0]
        await mm.uploadAll(resp)
        sucesso('Atualizado...')
        this.$router.push('/sistema/destaque')
      }
      this.isActive = false
    },
    async getDados() {
      const dados = await Services.get(
        `/destaque/${this.$route.params.id}`
      ).catch(response => {
        console.log('error: ' + response)
        return (this.isActive = false)
      })
      this.form.title = dados.title
      this.allImages = dados.img
        ? [{ id: this.$route.params.id, images: dados.img }]
        : ''
      this.allImagesMobile = dados.img_mobile
        ? [{ id: this.$route.params.id, images: dados.img_mobile }]
        : ''
      this.form.ativo = dados.ativo
      this.form.url = dados.url
      this.isActive = false
    },
    verifPermissao() {
      if (this.$route.params.id) {
        if (!this.ACTION.e) {
          this.$router.push('/erro')
        }
      } else {
        if (!this.ACTION.c) {
          this.$router.push('/erro')
        }
      }
    }
  },
  mounted() {
    this.verifPermissao()
    if (this.$route.params.id) {
      this.isActive = true
      this.getDados()
    }
  }
}
</script>

<style lang="stylus" scoped>
.image {
    max-height: 200px;
}
</style>
